import React from 'react';
import classnames from 'classnames';
import { shape, string, object, number, arrayOf } from 'prop-types';
import { Image } from 'nordic/image';
import { Button } from '@andes/button';
import { Card as AndesCard } from '@andes/card';

import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';

import { trackEvent } from '../../../../../services/tracking';
import { LAZYLOAD_OFF } from '../../../../../../utils/constants';

const namespace = 'dynamic-access-card-grid-media';

const CardGridMedia = ({ card, cardHeightClass, track_event }) => {
  const { header, pictures, description, action } = card;

  const handleClick = () => {
    if (track_event) {
      trackEvent(track_event);
    }

    window.location.assign(action.target);
  };

  return (
    <AndesCard
      className={classnames('dynamic-access-card', namespace, cardHeightClass)}
      onClick={handleClick}
    >
      <div>
        {header && (
          <>
            <h2 className={`${namespace}__title`}>{header.text}</h2>
          </>
        )}
        {pictures && (
          <div
            className={`${namespace}__pictures ${namespace}__pictures--${pictures.length}`}
          >
            {pictures.slice(0, 2).map((picture) => (
              <div
                className={`${namespace}__pictures-container`}
                key={picture.id}
              >
                <Image
                  src={picture.src}
                  alt={picture.alt}
                  lazyload={LAZYLOAD_OFF}
                  preload
                />
              </div>
            ))}
          </div>
        )}

        {description && (
          <div>
            <span className={`${namespace}__item-description`}>
              {description.text}
            </span>
          </div>
        )}
      </div>

      {action && (
        <Button
          hierarchy={action.label.hierarchy}
          size={action.label.size}
          label={action.label.text}
          className={`${namespace}__action`}
          href={action.target}
        >
          {action.label.text}
          <span className="andes-visually-hidden">{action.label.text}</span>
        </Button>
      )}
    </AndesCard>
  );
};

CardGridMedia.propTypes = {
  card: shape({
    permalink: string,
    header: shape({
      text: string,
    }),
    pictures: arrayOf(
      shape({
        picture: shape({
          src: string,
          alt: string,
          id: string,
        }),
      }),
    ),
    description: shape({
      text: string,
    }),
    name: shape({
      text: string,
    }),
    shipping: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    tag: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    attributes: shape({
      text: string,
    }),
    price: shape({
      original_price: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
      }),
      current_value: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
        suffix: string,
      }),
      discount: shape({
        value: number,
      }),
    }),
    action: shape({
      label: shape({
        color: string,
        font_family: string,
        font_size: string,
        hierarchy: string,
        size: string,
        bg_color: string,
        text: string,
      }),
      target: string,
    }),
  }),
  track_event: object,
  cardHeightClass: string,
};

export default withTracker(withTrackerOnPrint(CardGridMedia));
