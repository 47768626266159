
import { STATIC_ITEM, ITEMS_IMAGES_GRID } from '../../../../../utils/constants';

export default function adjustCardHeight(cards) {
  const items = cards.filter(key => key.type === STATIC_ITEM || key.type === ITEMS_IMAGES_GRID);
  if (items && items.length > 0) {
    return items.some(key => (key.content.price && key.content.price.original_price));
  }
  return false;
}
