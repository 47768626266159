import React from 'react';
import classnames from 'classnames';
import { shape, string, object, number, arrayOf } from 'prop-types';
import { Image } from 'nordic/image';
import { MoneyAmountCombo } from '@andes/money-amount';
import { Card as AndesCard } from '@andes/card';

import modelMoneyAmountCombo from '../../utils/model-money-amount-combo';
import Action from '../../../action';
import Highlight from '../../../highlight';
import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';
import { trackEvent } from '../../../../../services/tracking';
import StyledLabelFormated from '../../../styled-label/styled-label-formated';

import { LAZYLOAD_OFF, DISCOUNT_FONT_SIZE, ALL_ITEMS_IMAGES } from '../../../../../../utils/constants';

const namespace = 'dynamic-access-card-item';

const CardItem = ({ card, track_event, cardHeightClass }) => {
  const {
    header,
    picture,
    name,
    permalink,
    price = {},
    shipping,
    tag,
    attributes,
    items_images,
    additional_items,
    highlight,
  } = card;
  const { original_price, current_value, discount } = price;
  const itemsImagesLength = items_images
    && items_images.length <= ALL_ITEMS_IMAGES ? items_images.length : ALL_ITEMS_IMAGES;

  const hasDescriptionComponents = discount || (shipping && shipping.text);

  const handleClick = () => {
    trackEvent(track_event);
  };

  return (
    <AndesCard
      className={classnames('dynamic-access-card', namespace, cardHeightClass)}
      onClick={handleClick}
    >
      {header && (
        <>
          <h2 className={`${namespace}__title`}>{header.text}</h2>
          <Action
            label={header.text}
            target={permalink}
            className={`${namespace}__title-hidden`}
          />
        </>
      )}
      {items_images && (
        <div className={`${namespace}__items-images ${namespace}__items-images--${itemsImagesLength}`}>
          {items_images.slice(0, 4).map((key, i) => (i !== ALL_ITEMS_IMAGES - 1
            ? (
              <div
                key={key.picture.id}
                className={`${namespace}__items-images-container`}
              >
                <Image
                  src={key.picture.src}
                  alt={key.picture.alt}
                  lazyload={LAZYLOAD_OFF}
                  preload
                />
              </div>
            )
            : (
              <div
                key={key.picture.id}
                className={
                  `${namespace}__items-images--last-image ${additional_items
                    ? `${namespace}__items-images--additional_items` : ''}`
                }
              >
                <Image
                  src={key.picture.src}
                  alt={key.picture.alt}
                  lazyload={LAZYLOAD_OFF}
                  preload
                />
                {additional_items > 0 && (
                  <span className={`${namespace}__items-images__label`}>
                    +{additional_items}
                  </span>
                )}
              </div>)))}
        </div>
      )}
      {!items_images && (
        <>
          {picture && (
            <Image
              src={picture.src}
              lazyload={LAZYLOAD_OFF}
              preload
              className={classnames(`${namespace}__image`, {
                [`${namespace}__image--${picture.format}`]: picture.format,
              })}
            />
          )}
          <Highlight
            className={`${namespace}__item-highlight`}
            highlightProperties={highlight}
          />
          {name && (
            <Action
              label={name.text}
              target={permalink}
              className={classnames(`${namespace}__item-title`)}
            />
          )}
          <div className={`${namespace}__item-container`}>
            {current_value
              && (<MoneyAmountCombo
                previousValue={modelMoneyAmountCombo(original_price)}
                currentValue={modelMoneyAmountCombo(current_value)}
                discount={discount && { ...discount, size: DISCOUNT_FONT_SIZE }}
                className={`${namespace}__price`}
              />)}
            {shipping && (
              <StyledLabelFormated
                as="div"
                dataModel={shipping}
                className={`${namespace}__shipping`}
              >
                {shipping.text && shipping.subText && shipping?.styles?.font_size && (
                  <span className={`${namespace}__subtext font-size--${shipping.styles.font_size}`}>
                    {shipping.subText}
                  </span>
                )}
              </StyledLabelFormated>
            )}
            {!hasDescriptionComponents && (
              <>
                {tag ? (
                  <div className={`${namespace}__container-tag`}>
                    <StyledLabelFormated
                      as="span"
                      dataModel={tag}
                    />
                  </div>
                ) : attributes && <span className={`${namespace}__item-attributes`}>{attributes.text}</span>}
              </>
            )}
          </div>
        </>
      )}
    </AndesCard>);
};


CardItem.propTypes = {
  card: shape({
    permalink: string,
    header: shape({
      text: string,
    }),
    name: shape({
      text: string,
    }),
    picture: shape({
      src: string,
    }),
    shipping: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    tag: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    attributes: shape({
      text: string,
    }),
    price: shape({
      original_price: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
      }),
      current_value: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
        suffix: string,
      }),
      discount: shape({
        value: number,
      }),
    }),
  }),
  track_event: object,
  cardHeightClass: string,
  items_images: arrayOf(shape({
    picture: shape({
      src: string,
      alt: string,
      id: string,
    }),
  })),
};

export default withTracker(withTrackerOnPrint(CardItem));
