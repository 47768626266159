import { STATIC_ITEM } from '../../../../../utils/constants';

export default function hasHighlightedCard(cards) {
  const items = cards.filter(key => key.type === STATIC_ITEM);

  if (items && items.length > 0) {
    return items.some(key => (key.content.highlight));
  }

  return false;
}
