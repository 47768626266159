import React from 'react';
import { object, string, shape, bool } from 'prop-types';
import classnames from 'classnames';

import CardItemMobile from './cards/card-item-mobile';
import CardIconMobile from './cards/card-icon-mobile';
import CardGridMediaMobile from './cards/card-grid-media-mobile';
import {
  STATIC_ITEM,
  STATIC_ICON,
  STATIC_ILLUSTRATION,
  ITEMS_IMAGES_GRID,
  STATIC_IMAGES_BUTTON,
} from '../../../../../utils/constants';

const DynamicAccessCardMobile = ({ card, adjustCardHeight, hasHighlightedCard, hasFirstShipping }) => {
  const {
    card_id,
    content,
    reco_track,
    track,
    type,
    track_event = null,
  } = card;

  const cardHeightClass = classnames(
    { 'dynamic-access-card--with-highlight': hasHighlightedCard },
    { 'dynamic-access-card--with-discount': adjustCardHeight && !hasHighlightedCard },
    { 'dynamic-access-card--with-fs': hasFirstShipping && !hasHighlightedCard && !adjustCardHeight },
  );

  switch (type) {
    case STATIC_IMAGES_BUTTON: {
      return (
        <CardGridMediaMobile
          card={content}
          reco_track={reco_track}
          track={track}
          track_event={track_event}
          key={card_id}
          cardHeightClass={cardHeightClass}
        />
      );
    }
    case STATIC_ITEM:
    case ITEMS_IMAGES_GRID: {
      return (
        <CardItemMobile
          key={card_id}
          card={content}
          reco_track={reco_track}
          track={track}
          track_event={track_event}
          cardHeightClass={cardHeightClass}
        />
      );
    }
    case STATIC_ICON:
    case STATIC_ILLUSTRATION: {
      return (
        <CardIconMobile
          key={card_id}
          card={content}
          reco_track={reco_track}
          track={track}
          track_event={track_event}
          type={type}
          cardHeightClass={cardHeightClass}
        />
      );
    }
    default:
      return null;
  }
};

DynamicAccessCardMobile.propTypes = {
  card: shape({
    type: string,
    card_id: string,
    content: object,
    track: object,
    reco_track: object,
    track_event: object,
  }),
  adjustCardHeight: bool,
  hasHighlightedCard: bool,
  hasFirstShipping: bool,
};

export default DynamicAccessCardMobile;
