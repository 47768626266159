import React, { useMemo } from 'react';
import { string, array } from 'prop-types';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import DynamicAccessCardMobile from './dynamic-access-card/mobile';
import withTracker from '../with-tracker';
import isCardHeightAdjusted from './utils/is-card-height-adjusted';
import isHighlightedCard from './utils/has-highlighted-card';
import isFirstShipping from './utils/isFirstShipping';

const DynamicAccessMobile = ({ type, cards, deviceType, title }) => {
  const adjustCardHeight = useMemo(() => isCardHeightAdjusted(cards), [cards]);
  const hasHighlightedCard = useMemo(() => isHighlightedCard(cards), [cards]);
  const hasFirstShipping = useMemo(() => isFirstShipping(cards), [cards]);

  return (
    <section type={type} className="dynamic-access">
      <CarouselFree srLabel={title} className={`carousel-${type}-${deviceType}`}>
        {cards.map((card) => (
          <CarouselFreeSlide key={card.card_id}>
            <DynamicAccessCardMobile
              card={card}
              adjustCardHeight={adjustCardHeight}
              hasHighlightedCard={hasHighlightedCard}
              hasFirstShipping={hasFirstShipping}
              deviceType={deviceType}
            />
          </CarouselFreeSlide>
        ))}
      </CarouselFree>
    </section>
  );
};

DynamicAccessMobile.propTypes = {
  type: string,
  cards: array,
  deviceType: string,
  title: string,
};

export default withTracker(DynamicAccessMobile);
